import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { isExists, navigateToPage } from 'gatsby-theme-husky/src/utils/functions';
import { MEN_CATEGORY_PATH } from 'gatsby-theme-husky/src/utils/constants';
import { isBrowser } from 'gatsby-theme-husky/src/utils/browser';

import { toBoolean } from 'gatsby-theme-husky/src/utils/stringUtils/stringUtils';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import Button from 'gatsby-theme-husky/src/common/Button';
import VideoWithThumbnail from 'gatsby-theme-husky/src/components/VideoNaturalBanner/VideoWithThumbnail/VideoWithThumbnail';
import { BannerProps } from 'gatsby-theme-husky/src/common/Banner/models';
import UmbracoImage from '../Image/UmbracoImage';

import 'gatsby-theme-husky/src/common/Banner/Banner.scss';
import 'gatsby-theme-husky/src/common/Banner/MainBannerOverride.scss';

const Banner: FC<BannerProps> = ({
  bannerTitle,
  content,
  bannerVariant,
  backgroundColor,
  isWithWrapper,
  imageAsBackground,
  imageAsBackgroundAlt,
  bannerFlourishImage,
  button,
  bannerTitleLink,
  withVideo,
  lazyload = true,
  isPlayButton,
  showContentOnPlay = false,
}) => {
  // TODO refactor button node to be of one type
  const [isVisibleVideo, setIsVisibleVideo] = useState(false);
  const { link, ariaLabel, text, withReverse } = Array.isArray(button) ? button[0] : button || {};
  const titleLink = bannerTitleLink ? bannerTitleLink[0] : null;
  const handleNavigation = () => navigateToPage(link);
  const { title, link: src, image, autoplay } = Array.isArray(withVideo)
    ? withVideo[0]
    : withVideo || {};
  const handlePlayVideo = () => setIsVisibleVideo(true);

  return (
    <div
      className={classNames('banner', {
        [`banner--${bannerVariant}`]: bannerVariant,
        'banner--with-wrapper': isWithWrapper,
        'banner--with-image-as-background': imageAsBackground,
        [`banner--${bannerVariant}__title--wrapper`]: bannerTitle,
      })}
      data-testid="banner"
      style={{
        backgroundColor,
      }}
    >
      {bannerTitle ? (
        <h2 className={classNames({ [`banner--${bannerVariant}__title`]: bannerTitle })}>
          {bannerTitle}
        </h2>
      ) : null}
      {bannerFlourishImage ? (
        <UmbracoImage
          {...{ lazyload }}
          image={bannerFlourishImage}
          className="banner__flourish-image"
        />
      ) : null}
      {imageAsBackground ? (
        <UmbracoImage
          {...{ lazyLoad: false, eagerLoad: true }}
          image={imageAsBackground}
          alt={imageAsBackgroundAlt!}
          className={classNames('banner__image banner--with-image-as-background__image', {
            [`banner--${bannerVariant}__image`]: bannerVariant,
          })}
        />
      ) : null}
      {withVideo ? (
        <VideoWithThumbnail
          {...{
            image,
            setIsVisibleVideo,
            isVisibleVideo,
            imageAsBackgroundAlt,
            bannerVariant,
            src,
            title,
            autoplay,
            isPlayButton,
          }}
        />
      ) : null}
      <div
        data-testid="banner-content"
        className={classNames('banner__content', {
          [`banner--${bannerVariant}__content`]: bannerVariant,
          'banner--with-image-as-background__content': imageAsBackground,
        })}
      >
        {titleLink ? (
          <Link to={titleLink.url} aria-label={titleLink.name} className="banner__title-link">
            {titleLink.name}
          </Link>
        ) : null}
        {withVideo && (showContentOnPlay ? false : isVisibleVideo) ? null : (
          <DangerouslySetInnerHtml html={content} />
        )}
        {ariaLabel && (showContentOnPlay ? true : !isVisibleVideo) ? (
          <Button
            {...{
              ariaLabel: ariaLabel!,
              onClick: isPlayButton ? handlePlayVideo : handleNavigation,
              className: classNames('banner__button', {
                [`banner--${bannerVariant}__button`]: bannerVariant,
                'men-banner': isBrowser() && isExists(window.location.pathname, MEN_CATEGORY_PATH),
              }),
              isReversed: toBoolean(withReverse!),
            }}
          >
            {text}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default Banner;
